import React from "react";
import { graphql } from "gatsby";
import { navigate } from "gatsby";

export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  return (
    <article className="blog-page">
      <div className="flex center">
        <button
          aria-label="back button"
          className="icon blog-back-btn"
          onClick={() => navigate("/blog/")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            height="20px"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <div>
          <p className="author">Pablo Jurado</p>
          <p className="date">{post.frontmatter.date}</p>
        </div>
      </div>

      <h1>{post.frontmatter.title}</h1>
      <p>{post.frontmatter.description}</p>
      <div
        className="blog-content"
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
    </article>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "DD MMMM, YYYY")
      }
    }
  }
`;
